<template>
  <div class='home'>
    <div class="tabs-nav">
      <div class="tabs ">
        <div class="tabs-item" :class="{ 'tabs-active': index == activeid }" v-for="(item, index) in tabsList"
          :key="index" @click="tabsBtn(index)">{{ item.name }}</div>
      </div>
      <div class="btn-box">
        <template v-if="usertype">
          <div class="share" @click="goInvite">
            <img src="../assets/home/gift.png" alt="">
            <div class="text">邀请好友</div>
          </div>
          <img @click="goUser" class="avatar"
            :src="userInfo.userDetailVO.avatarUrl == 'N/A' ? avatar_def : userInfo.userDetailVO.avatarUrl" alt="">
        </template>
        <template v-else>
          <div class="btn" @click="goLogin">登录/注册</div>
        </template>
      </div>
    </div>
    <div class="worklist-box" :style="'height: ' + (this.mobileHeight - 44 - 22) + 'px'">
      <div class="work-grid" :style="'height: ' + (this.mobileHeight - 44 - 22) + 'px'" @scroll="handleScroll"
        data-id="3" ref="scrollContainer">
        <div class="work-grid-item" v-if="workList != ''">
          <span v-for="(item, index) in workList" :key="index" @click="goWorkDetail(item.id)">
            <Cover :imgUrl="item.coverUrl">
            </Cover>
          </span>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ProjectsByTimeDesc,
  ProjectsByAutoCalculate,
  FolloweeProjectsPageable,
} from "@/api/work";
import { GetUserProfile } from "@/api/user";
import Cover from "@/components/Cover/index";
import { getUser } from "@/utils/auth";
import avatar_def from '@/assets/common/avatar-def.png'
import { Toast } from 'vant';
import { mixinstest } from '@/mixins/index'

export default {
  mixins: [mixinstest],

  name: '',
  components: {
    Cover
  },
  data() {
    return {
      avatar_def: avatar_def,
      scroll: 0,
      activeid: 0,
      tabsList: [
        {
          name: '全部',
          active: true
        },
        {
          name: '最新',
          active: false
        },
        {
          name: '关注',
          active: false
        },

      ],
      current: 1,
      size: 20,
      intervalId: null,
      workList: [],
      result: false,
      mobileHeight: 0,
      logState: this.publics.onToken(), //登录
      userInfo: '',
      workLoading: false,
      usertype: false,
    }
  },
  created() {
    document.title = 'Aimber-原创、专业的数字艺术家平台'
    if (getUser() != undefined) {
      this.userInfo = JSON.parse(getUser());
      this.usertype = true
    }
  },
  mounted() {
    this.getProjectsByDesc()
    this.mobileHeight = window.innerHeight;
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    goInvite() {
      this.$router.push({ path: '/share' });
    },
    goWorkDetail(id) {
      this.$router.push({ path: '/workDetails', query: { workId: id } });
    },
    // handleScroll(event) {
    //   // 变量scrollTop为当前页面的滚动条纵坐标位置
    //   const { scrollTop, clientHeight, scrollHeight } = event.target;
    //   // 到底的条件
    //   if (scrollTop + clientHeight >= scrollHeight) {
    //     //到底后要去触发的事件
    //     if (this.workLoading) return; //控制节流
    //     this.getProjectsByDesc();
    //   }
    // },
    goUser() {
      this.$router.push({ path: '/user' });
    },
    goLogin() {
      this.$router.push({ path: '/login' });
    },
    handleResize() {
      this.mobileHeight = window.innerHeight;
    },
    tabsBtn(index) {
      this.workList = []
      this.activeid = index
      this.current = 1,
        this.size = 20,
        this.getProjectsByDesc()
    },
    //获取首页作品列表
    getProjectsByDesc() {
      // 加载状态结束

      // this.result = false
      this.workLoading = true;
      let params = {
        current: this.current,
        size: this.size,
      };
      if (this.activeid == 0) {
        ProjectsByAutoCalculate(params)
          .then((responses) => {
            this.dataHandle(responses);
          })
          .catch((reject) => {
            console.log("reject", reject);
          });
      } else if (this.activeid == 1) {
        ProjectsByTimeDesc(params)
          .then((responses) => {
            this.dataHandle(responses);
          })
          .catch((reject) => {
            console.log("reject", reject);
          });
      } else if (this.activeid == 2) {
        if (this.logState) {
          FolloweeProjectsPageable(params)
            .then((responses) => {
              this.dataHandle(responses);
            })
            .catch((reject) => {
              console.log("reject", reject);
            });
        } else {
          this.goLogin()
        }
      }
    },
    // 处理列表数据
    dataHandle(responses) {
      if (responses.code == 200) {
        // if (Number(responses.data.total) == 0) {
        //   this.result = true
        // }
        this.current++;
        if (this.workList.length >= Number(responses.data.total)) {
          this.workLoading = true;
          return;
        } else {
          this.workLoading = false;
        }
        if (responses.data.records.length > 0) {
          this.workList = this.workList.concat(responses.data.records)
        }

      }
    },
    stopCopying() {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    //获取用户信息
    getUserInfo() {
      GetUserProfile().then((responses) => {
        if (responses.code == 200) {
          this.usertype = true
          let user = responses.data;
          this.userInfo = user
          this.$store.commit("user/SET_USERINFO", JSON.stringify(user));
        }
      }).catch((reject) => {
        console.log('reject', reject);
      });
    },

  },
  beforeRouteLeave(to, from, next) {
    this.scroll = this.$refs.scrollContainer.scrollTop;
    next();
  },
  activated() {
    if (getUser() != undefined) {
      this.userInfo = JSON.parse(getUser());
      this.usertype = true
    }
    if (this.scroll > 0) {
      this.$refs.scrollContainer.scrollTop = this.scroll;
    }
  },
}
</script>

<style lang='scss' scoped>
@import "../styles/global.scss";

.home {
  padding: 0 16px;

}

.tabs-nav {
  width: 100%;
  color: #FFFFFF;
  height: 44px;
  display: flex;


  .tabs {
    display: flex;

    align-items: flex-end;
    justify-content: left;
    gap: 24px;
    flex: 1;
    padding-bottom: 6px;
    box-sizing: border-box;

    .tabs-item {
      font-weight: 400;
      font-size: 18px;
      color: #CDCDCD;
      line-height: 19px;
    }

    .tabs-active {
      font-weight: 600;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 23px;
    }
  }

  .btn-box {
    padding-top: 5px;
    @include flex;

    .share {
      img {
        @include wh(16px, 16px)
      }

      .text {
        font-weight: 400;
        font-size: 10px;
        color: #FFFFFF;
        line-height: 12px;
      }
    }

    .avatar {
      @include wh(32px, 32px);
      margin-left: 8px;
      border-radius: 50%;
    }
  }
}



.worklist-box {

  // 作品 
  .work-grid {
    position: relative;
    overflow: scroll;
    padding-top: 16px;

    .work-grid-item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // padding: 0 14px;
      gap: 3px;
      overflow: scroll;
    }

    img {
      width: 100%;
    }

    .imagezp {
      border-radius: 4px;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }


  }

}
</style>
