import axios from "axios";
import store from "@/store";
import router from "@/router"; // 引入router实例
import {
  getToken,
  getWithExpiry,
  removeToken,
  removeUser,
  removeTokenItem,
} from "@/utils/auth";
import { GetUserProfile } from "@/api/user";
import { RenewalJwtToken } from "@/api/login";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false, // 确保不发送cookie
  // baseURL: "", // http://192.168.0.110:9999
  // withCredentials: true, // //跨域请求时发送cookie
  timeout: 70000, // request timeout
  headers: { "Content-Type": "application/json" },
});
window.isRefreshing = false;

// request interceptor
service.interceptors.request.use(
  (config) => {
    // console.log("config", getWithExpiry());
    let token = store.getters.token;
    let time = new Date().getTime();
    if (token != null && getWithExpiry() != null) {
      config.headers["Authorization"] = getWithExpiry().value;
      let expiry = getWithExpiry().expiry;
      if (expiry - time <= 2 * 24 * 3600000) {
        if (!window.isRefreshing) {
          window.isRefreshing = true;
          RenewalJwtToken()
            .then((responses) => {
              if (responses.code == 200) {
                store.commit("user/SET_TOKEN", responses.data);
                GetUserProfile()
                  .then((res) => {
                    if (res.code == 200) {
                      let data = JSON.stringify(res.data);
                      store.commit("user/SET_USERINFO", data);
                    }
                  })
                  .catch((reject) => {});
              }
            })
            .catch((reject) => {
              console.log("reject", reject);
            });
        }
      }
      // console.log(expiry-Math.floor(time/1000))
      // console.log("token", Math.floor(getWithExpiry().expiry/1000));
    }
    return config;
  },
  (error) => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // response interceptor
    const currentRoute = router.currentRoute;
    const { path } = currentRoute;
    // 获取当前路由信息

    if (res.code === 401 || res.code === 403) {
      removeTokenItem();
      if (path != "/login") {
        router.push(`/login?redirect=${currentRoute.query.redirect}`);
      }
    } else {
      return res;
    }
  },
  (error) => {
    // console.log("err", error); // for debug
    const currentRoute = router.currentRoute;
    const { path } = currentRoute;
    // 获取当前路由信息
    console.log("当前路由:", error);
    if (
      error.code == "ECONNABORTED" ||
      error.code == "ERR_NETWORK" 
    ) {
      return Promise.reject(error);
    } else {
      if (error.response.status === 401 || error.response.status === 403) {
        removeTokenItem();
        console.log("进入");
        if (path != "/login") {
          router.push(`/login?redirect=${currentRoute.query.redirect}`);
        }
      } else if (error.response.status >= 500) {
      }
    }
    return Promise.reject(error);
  }
);

export default service;
