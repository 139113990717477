import { formatTime } from "@/utils/index";
import { Toast } from "vant";
import success from "@/assets/common/success.png";

// 创建一个需要混入的对象
export const mixinstest = {
  data() {
    return {
      testData: "混入对象的data",
      show: "",
      logState: this.publics.onToken(), //登录
    };
  },
  created() {
    // console.log("这是混入对象的created");
  },
  methods: {
    // 返回上一页
    back() {
      if (this.logState) {
        if (this.$route.path != "/user") {
          if (this.$route.path == "/share") {
            this.$router.push({ path: "/" });
          } else {
            this.$router.go(-1);
          }
        } else {
          this.$router.push({ path: "/" });
        }
      } else {
        if (this.$route.path == "/protocol") {
          this.$router.push({ path: "/login" });
        } else {
          this.$router.push({ path: "/" });
        }
      }
    },
    // 关闭弹窗
    close(urgeShow) {
      this.show = false;
    },
    // 时间格式化
    getTime(time) {
      return formatTime(time);
    },

    // 数字格式化
    numfix(num) {
      return num / 10000 >= 1
        ? (num / 10000).toFixed(1) + "w"
        : num / 1000 >= 1
        ? (num / 1000).toFixed(1) + "k"
        : num;
    },
    //触底加载
    handleScroll(event) {
      let id = event.target.dataset.id;
      // 变量scrollTop为当前页面的滚动条纵坐标位置
      const { scrollTop, clientHeight, scrollHeight } = event.target;

      // 到底的条件
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        //到底后要去触发的事件
        if (id == 0) {
          if (this.isLoading) return; //控制节流
          this.getProjectComment();
        } else if (id == 1) {
          if (this.wholeisLoading) return; //控制节流
          this.getProjectsByAuthorId();
        } else if (id == 2) {
          if (this.inviteLoading) return; //控制节流
          this.getInviteePageable();
        } else if (id == 3) {
          if (this.workLoading) return; //控制节流
          this.getProjectsByDesc();
        }
      }
    },

    //作品详情
    goWorkDetail(id) {
      this.$router.push({ path: "/workDetails", query: { workId: id } });
    },

    //复制链接
    copyUrl(text) {
      let url = window.location.origin;
      const input = document.createElement("input");
      document.body.appendChild(input);
      if (text == "share") {
        input.setAttribute(
          "value",
          url + "/login?invite_code=" + this.userInfo.uid
        );
      } else if (text == "workDetails") {
        input.setAttribute("value", url + "/workDetails?workId=" + this.workId);
      }

      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        document.body.removeChild(input);
        Toast({
          message: "已复制链接",
          icon: success,
        });
        this.morePopup = false;
      } else {
      }
    },
  },
  computed: {
    // testData2() {
    //   return this.testData + "：这是混入对象计算结果。";
    // },
  },
  watch: {
    // testData(newVal, oldVal) {
    //   console.log("混入对象的watch");
    // },
  },
};
