import Cookies from "js-cookie";

const TokenKey = "Authorization";
const UserKey = "Userinfo";
const EventKey = "EventKey"; //微信登录
const stateKey = "stateKey"; //随机码
const inviteCode = "inviteCode"; //邀请码
let seconds = 10;

// let expires = new Date(new Date() * 1 + seconds * 1000);
let expires = 30;
const ttl = 1000 * 60 * 60 * 24 * 30;
// const ttl = expires;
console.log(expires);
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: expires });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUser() {
  return Cookies.get(UserKey);
}

export function setUser(user) {
  return Cookies.set(UserKey, user, { expires: expires });
}

export function removeUser() {
  return Cookies.remove(UserKey);
}

export function getEventKey() {
  return Cookies.get(EventKey);
}

export function setEventKey(user) {
  return Cookies.set(EventKey, user);
}

export function removeEventKey() {
  return Cookies.remove(EventKey);
}

export function setWithExpiry(value) {
  const now = new Date();
  // ttl是过期时间（单位：毫秒）
  const item = {
    value:value? "Bearer " + value:'',
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(TokenKey, JSON.stringify(item));
}

export function getWithExpiry() {
  const itemStr = localStorage.getItem(TokenKey);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(TokenKey);
    return null;
  }
  return item;
}
export function getState() {
  return Cookies.get(stateKey);
}
export function setState(state) {
  return Cookies.set(stateKey, state);
}
export function getInviteCode() {
  return Cookies.get(inviteCode);
}
export function setInviteCode(id) {
  return Cookies.set(inviteCode, id);
}
export function removeInviteCode() {
  return Cookies.remove(inviteCode);
}
export function removeTokenItem() {
  removeUser();
  removeToken();
  removeEventKey();
  localStorage.clear();
  return localStorage.removeItem(TokenKey);
}
