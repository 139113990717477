import request from '@/utils/request'
const name= '/aimber'
export function GetUserProfile(data) {
  return request({
    url: name+'/user-profile/profile',
    method: 'get',
    params: data
  })
}

export function UpdateUserProfile(data) {
  return request({
    url: name+'/user-profile/update',
    method: 'PUT',
    data
  })
}
//黑名单列表
export function GetUserBlackList(data) {
  return request({
    url: name+'/user-profile/black-list',
    method: 'post',
    data
  })
}
//添加黑名单
export function AddUserToBlackList(data) {
  return request({
    url: name+'/user-profile/add-black-list',
    method: 'post',
    data
  })
}
//移除黑名单
export function RemoveUserFromBlackList(data) {
  return request({
    url: name+'/user-profile/remove-black-list',
    method: 'post',
    data
  })
}
export function AuthorWithUser(data) {
  return request({
    url: name+'/public/author/'+data,
    method: 'get',
  })
}

// 关注列表
export function UserFollowList(data) {
  return request({
    url: name+'/public/follow/follow-list',
    method: 'post',
    data
  })
}
// 粉丝列表
export function UserFunList(data) {
  return request({
    url: name+'/public/follow/fun-list',
    method: 'post',
    data
  })
}

// 推荐用户列表
export function RecommendedAuthors(data) {
  return request({
    url: name+'/user/recommend',
    method: 'get',
    params: data
  })
}

// 删除用户封面
export function DeleteUserCover(data) {
  return request({
    url: name+'/user-profile/clear-cover-url',
    method: 'DELETE',
  })
}




export function logout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  })
}

export function captcha(uuid) {
  return request({
    url: '/service/captcha.jpg',
    method: 'get',
    params: { uuid }
  })
}
