import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import  "./permission";
import publics from "./utils/public";
Vue.prototype.publics = publics;
import { Button } from "vant";
import { Image as VanImage } from "vant";
import { Lazyload } from "vant";
import { Popup } from "vant";
import { PasswordInput, NumberKeyboard } from "vant";
import { Field } from "vant";
import { List } from "vant";
import { PullRefresh } from "vant";
import { Swipe, SwipeItem } from "vant";
import { Col, Row } from "vant";

Vue.use(Col);
Vue.use(Row);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Field);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Popup);
Vue.use(Lazyload);
Vue.use(Button);
Vue.use(VanImage);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
