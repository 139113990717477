import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(Router)
let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/index.vue')
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import(/* webpackChunkName: "about" */ '../views/protocol/index.vue')
  },
  {
    path: '/workDetails',
    name: 'workDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/details/index.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import(/* webpackChunkName: "about" */ '../views/invite/index.vue')
  },
]

const router = new Router({
  mode: 'history',
  routes
})

export default router
