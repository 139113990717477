/*
 * @Author: wuhuan whuan_wu@scgcube.com
 * @Date: 2023-07-03 17:54:46
 * @LastEditors: wuhuan whuan_wu@scgcube.com
 * @LastEditTime: 2023-07-03 17:55:03
 * @FilePath: \demo\src\store\modules\histroy.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 一个完整的模块

// 1.状态变量
const state = {
  // 存储浏览历史
  histroy: [],
}

// 2.同步方法 修改state变量
const mutations = {
  SET_HISTROY: (state, histroy) => {
    state.histroy.unshift({ createTime: new Date(), histroy: histroy })
  },
 
}

// 3.异步调用mutations内的修改state的方法
const actions = {
  setHistroy({ commit }, histroy) {
    return new Promise(resolve => {
      commit('SET_HISTROY', histroy)
    })
  },
}

// 4.变量暴露出去
export default {
  namespaced: true,
  state,
  mutations,
  actions
}


