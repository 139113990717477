<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>

export default {
  name: "App",
  data() {
    return {
    };
  },
  created() {
    //调试入口
    // let script = document.createElement("script");
    // script.src = "https://cdn.bootcss.com/eruda/1.3.0/eruda.min.js";
    // script.type = "text/javascript";
    // document.getElementsByTagName("head")[0].appendChild(script);
    // script.onload = function () {
    //   eruda.init();
    // };
 
  },
  watch: {
    "$store.state.user.userInfo": {
      handler: function (newVal, oldVal) {
        // this.loginShow = newVal
        this.userInfo = JSON.parse(newVal);
        // console.log(this.userInfo)
      },
    },
  }
}
</script>

<style lang="scss">
@import "./styles/global.scss";

html {
  background: var(--primary-background-color);
}

html,
body,
ul {
  margin: 0;
}

body {
  // padding: 0 16px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 自定义整个滚动条 */
::-webkit-scrollbar {
  overflow-x: scroll;
  scrollbar-width: none;
  /* 隐藏标准滚动条（仅适用于较新的浏览器）*/
  width: 0px;
  /* 设置滚动条的宽度 */
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.btn {
  width: 78px;
  height: 33px;
  background: #282828;
  border-radius: 8px;
  @include flex;

  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 14px;
}



.line {
  width: 32px;
  height: 2px;
  background: #999999;
  border-radius: 18px;
  margin: 8px auto 12px;
}

.title-popup {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 19px;
  text-align: center;
  padding-bottom: 16px;
}
</style>
