import { getToken, setToken, setWithExpiry,setUser } from "@/utils/auth";

const state = {
  token: getToken(),
  userInfo: {},
  loginShow:false
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token);
    setWithExpiry(token);
  },
  SET_USERINFO: (state, data = '') => {
    state.userInfo = data
    setUser(data);
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
