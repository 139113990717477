<template>
    <div style="display: flex;">
        <!-- <van-image :src="imgUrl == 'N/A' ? coverReviewFailed : urls">
            <template v-slot:loading>
                <img src="@/assets/small_square.png" alt="">
            </template>
<template v-slot:error>
                <img src="@/assets/small_square.png" alt="">
            </template>
</van-image> -->

        <img class="works-cover" :src="imgUrl == 'N/A' ? coverReviewFailed : urls" 
        @error="onImageError" alt="">
    </div>

</template>

<script>
import coverReviewFailed from '../../assets/cover-review-failed.png'
export default {
    props: {

        imgUrl: {
            type: String,
            default: "",
        },
        userId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            works_cover: require('@/assets/error/works-cover.png'),

            coverReviewFailed: coverReviewFailed,
            urls: '',
            timestamp: new Date().getTime(),
        };
    },
    created() {
    },
    mounted() {
        this.createThumbnail(this.imgUrl)
    },
    watch: {
        "imgUrl": {
            handler: function (newVal, oldVal) {
                this.createThumbnail(newVal)
            },
        },
    },
    computed: {

    },
    methods: {
        handleImageError(event) {
	        event.target.src = require('@/assets/small_square.png') // 只需要将此路径换成对应的默认图片路径即可
	      },
        onImageError(event) {
            let img = event.srcElement;
            img.src = this.works_cover;
            img.onerror = null;
        },
        errorHandler(e){
            console.log(e)

        },
        createThumbnail(item) {
            const img = new Image();
            img.src = item;
            // img.crossOrigin = "Anonymous";
            img.onload = () => {
                let maxSide = Math.min(img.width, img.height);
                const canvas = document.createElement("canvas");
                img.crossOrigin = "*";
                const ctx = canvas.getContext("2d");
                const thumbnailWidth = 500; // 缩略图的宽度
                const thumbnailHeight = 500; // 缩略图的高度
                canvas.width = thumbnailWidth;
                canvas.height = thumbnailHeight;
                ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);
                if (maxSide > 1000) {
                    this.urls = canvas.toDataURL("image/jpeg", 0.7)
                }
                else if (maxSide > 600 && maxSide < 1000) {
                    this.urls = canvas.toDataURL("image/jpeg", 0.9)
                }
                else {
                    this.urls = item
                }
            };
        },

        getJump() {
            if (this.userId != '') {
                this.$router.push({ path: '/user', query: { uid: this.userId } });
            }
        },
        errorHandler() {
            return true
        }
    },
};
</script>
<style scoped lang="scss">
.works-cover {
    width: 100%;
    object-fit: cover;
    background: url('@/assets/small_square.png');
    background-size: 100% 100%;
    border-radius: 2px;
}

.image-slot {
    display: flex;
}

.error {}

.van-image {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    display: flex;

}
</style>
